<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import HeartIcon from '@/Icons/HeartIcon.svelte';
    import TrashIcon from '@/Icons/TrashIcon.svelte';
    import { Button, buttonVariants } from '$lib/components/ui/button/index.js';
    import * as Dialog from '$lib/components/ui/dialog/index.js';

    export let comment: any;
    export let currentUser: any;

    const dispatch = createEventDispatcher();

    $: userReacted = comment.reactions.some((reaction) => reaction.user_id === currentUser.id);

    let isDeleteDialogOpen = false;

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.getAttribute('content') || '' : '';

    function showDeleteCommentDialog() {
        isDeleteDialogOpen = true;
    }

    async function deleteComment() {
        try {
            const params = new URLSearchParams();
            params.append('id', comment.id);

            const response = await fetch(`${app.baseUrl}/posts/comments/delete`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString(),
                credentials: 'same-origin',
            });

            const result = await response.json();

            if (result.success) {
                dispatch('commentDeleted', {
                    commentId: comment.id,
                    totalComments: comment.reactions.length - 1,
                });
                isDeleteDialogOpen = false;
                toast.success(result.message || 'Comment deleted');
            } else {
                toast.error(result.errors?.[0] || 'Failed to delete comment');
            }
        } catch (error) {
            toast.error('Error deleting comment');
            console.error('Error:', error);
        }
    }

    function handleReact() {
        dispatch('react', { commentId: comment.id });
    }

    function handleReply() {
        dispatch('reply', { username: comment.author.username });
    }

    $: isReacted = comment.userReacted;
    $: reactionsCount = comment.reactions?.length || 0;
    $: reactionsLabel = reactionsCount === 1 ? 'like' : 'likes';
</script>

<div class="post-comment d-flex mb-3 flex-row" data-commentID="{comment.id}">
    <div class="size-10">
        <img class="rounded-circle" src="{comment.author.avatar}" alt="{comment.author.username}" />
    </div>
    <div class="w-100 pl-3">
        <div class="d-flex justify-content-between flex-row">
            <div class="d-flex align-items-center font-bold">
                <div class="pr-1">{comment.author.name}</div>
                <a href="/profile/{comment.author.username}" class="text-dark-r">{comment.author.username}</a>
                {#if comment.author.email_verified_at}
                    <span class="ml-1" title="Verified user">vrfy</span>
                {/if}
            </div>
            <div class="separator">
                <div class="d-flex">
                    {#if currentUser.id == comment.author.id}
                        <span
                            class="h-pill h-pill-primary react-button ml-1 size-8 rounded"
                            title="Delete"
                            on:click="{showDeleteCommentDialog}"
                        >
                            <TrashIcon />
                        </span>
                    {:else}
                        <span
                            class="h-pill h-pill-primary react-button rounded"
                            class:active="{userReacted}"
                            title="{userReacted ? 'Unlike' : 'Like'}"
                            on:click="{handleReact}"
                        >
                            <HeartIcon filled="{userReacted}" />
                        </span>
                    {/if}
                </div>
            </div>
        </div>
        <div>
            <div class="text-break">{comment.message}</div>
            <div class="d-flex text-muted">
                <div>{new Date(comment.created_at).toLocaleTimeString()}</div>
                <div class="ml-2">
                    <span class="comment-reactions-label-count">{reactionsCount}</span>
                    <span class="comment-reactions-label">{reactionsLabel}</span>
                </div>
                <div class="ml-2">
                    <a href="javascript:void(0)" on:click="{handleReply}" class="text-muted">Reply</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Dialog -->
<Dialog.Root bind:open="{isDeleteDialogOpen}">
    <Dialog.Trigger class="hidden"></Dialog.Trigger>
    <Dialog.Content class="sm:max-w-[425px]">
        <Dialog.Header>
            <Dialog.Title>Delete Comment</Dialog.Title>
            <Dialog.Description
                >Are you sure you want to delete this comment? This action cannot be undone.</Dialog.Description
            >
        </Dialog.Header>
        <Dialog.Footer>
            <Button on:click="{deleteComment}">Delete</Button>
            <Button variant="outline" on:click="{() => (isDeleteDialogOpen = false)}">Cancel</Button>
        </Dialog.Footer>
    </Dialog.Content>
</Dialog.Root>
